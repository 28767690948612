
*{
    margin: 0;
  font-family: Arial, Helvetica, sans-serif;
    
}

/** HEADER **/

.navbar {
    overflow: hidden;
    background-color: #333; 
    padding-left: 15px;
    cursor: pointer;
  }
  
  .navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 15px;
    text-decoration: none;
  }
  
  .subnav {
    float: left;
    overflow: hidden;
    cursor: pointer;
  }
  
  .subnav .subnavbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    cursor: pointer;
  }

  .logout{
    background-color: red;
    margin-left: 80%;
  }

  .navbar a:hover, .subnav:hover .subnavbtn {
    background-color: #04AA6D;
  }
  
  .subnav-content {
    display: none;
    position: absolute;
    left: 0;
    background-color: #04AA6D;
    width: 100%;
    z-index: 1;
  }
  
  .subnav-content a {
    float: left;
    color: white;
    text-decoration: none;
  }
  
  .subnav-content a:hover {
    background-color: #eee;
    color: black;
  }
  
  .subnav:hover .subnav-content {
    display: block;
  }

  /** LOGIN **/ 

.login-form{
    margin-top: 100px;
    display: flex;
    justify-content: center;
}

input[type=text], input[type=password]{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0 ;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

input[type=submit]{
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}
.container{
  padding: 70px 30px;
}

label{
  font-weight: bold;
}


.table{
  margin-top: 30px;
  border-collapse: separate;
  border-spacing: 0;
  padding: 8px;
}

.table td, .table th {
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
}

.table tr:nth-child(even){background-color: #f2f2f2;}

.table tr:hover {background-color: #ddd;}

th {
  position: sticky;
  top: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #04AA6D;
  color: white;
}

td:first-child{
  font-weight: bold;
}

.action-buttons{
  display: flex;
}


.trashIcon, .editIcon, .eyeIcon, .checkGreen, .checkRed{
  cursor: pointer;
  transition: all .2s;
}

.trashIcon:hover, .editIcon:hover, .eyeIcon:hover, .checkGreen:hover, .checkRed:hover{
  scale: 1.2;
}

.trashIcon, .checkRed{
  color: red;
  margin-left: 35px;
}

.checkGreen{
  margin-left: 35px;
}


.editIcon, .checkGreen{
  color: green;
}

.eyeIcon:hover{
  color: blue;
}

button{
  background: none;
  border: none;
}

.client-form{
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.errorListing{
  text-align: center;
  margin-top: 100px;
}

.circle-floating-button{
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: green;
  padding: 5px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 80px;
  cursor: pointer;
  transition: all .2s;
}

.circle-floating-button:hover{
  scale: 1.2;
}

.icon-add{
  height: 30px;
  color: white;
}

.mg-all-100{
  margin: 100px;
}


.flex{
  display: flex;
  align-items: center;
  gap: 80px;
}

a{
  text-decoration: none;
  color: black;
}